<template>
    <div>
        <div class="main-container">
            <div class="container">
                <div class="row mb-3">
                    <div class="col-md-12">
                        <h3>Submit Your Feedback</h3>
                    </div>
                </div>

                <div class="row" v-if="isError">
                    <div class="col-md-6 col-sm-8 col-12">
                        <div class="alert alert-danger" role="alert">
                            <span>Sorry, please fill all the information below before submission.</span>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="isSubmitted">
                    <div class="col-md-6 col-sm-8 col-12">
                        <div class="alert alert-success" role="alert">
                            <span>Thanks! Your feedback has been submitted.</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label for="">Name</label>
                    </div>
                    <div class="col-md-6 col-sm-8 col-12">
                        <input type="text" class="form-control" placeholder="Full name" required v-model="name">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <label for="">E-mail</label>
                    </div>
                    <div class="col-md-6 col-sm-8 col-12">
                        <input type="email" class="form-control" placeholder="E-mail" required v-model="email">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <label for="">Phone number</label>
                    </div>
                    <div class="col-md-6 col-sm-8 col-12">
                        <input type="text" class="form-control" placeholder="Phone number" required v-model="phone">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <label for="">Message</label>
                    </div>
                    <div class="col-md-6 col-sm-8 col-12">
                        <textarea class="form-control" rows="3" required v-model="content"></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6 col-sm-8 col-12 text-right">
                        <button class="btn btn-primary" @click="submitFeedback">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '../../../utils/API'
export default {
    data() {
        return {
            isError: false,
            isSubmitted: false,
            name: null,
            email: null,
            phone: null,
            content: null,
        }
    },
    methods: {
        submitFeedback(){
            this.isError = false
            if(!this.name || !this.email || !this.phone || !this.content) {
                this.isError = true
                return ''
            }
            
            API.post('/feedbacks', {
                name: this.name,
                email: this.email,
                phone: this.phone,
                content: this.content
            }).then(res => {
                // console.log('res : ',res.data)
                this.isSubmitted = true
                this.name = null
                this.email = null
                this.phone = null
                this.content = null
            })
        }
    }
}
</script>

<style scoped>
.btn.btn-primary {
    background-color: #a91a18;
    border-color: #a91a18;
}

/* Change bootstrap input focus */
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border: 2px solid #a91a18;
  box-shadow: unset;
  /* border-color: #a91a18; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #a91a18; */
  outline: 0 none;
}
</style>